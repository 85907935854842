import Vue from 'vue'
import Vuex from 'vuex'
import bill from './modules/bill'
import payers from './modules/payers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bill,
    payers,
  },
})