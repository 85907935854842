import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import App from './App.vue'

import Home from './pages/Home.vue'
import Payers from './pages/Payers.vue'
import NotFound from './pages/NotFound.vue'

import "tailwindcss/tailwind.css"

Vue.config.productionTip = false

const routes = [
  { path: '/', component: Home },
  { path: '/payers', component: Payers },
  { path: '/*', component: NotFound },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

Vue.use(VueRouter)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
