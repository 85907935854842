<template>
  <nav>
    <ul class="flex">
      <li><router-link class="mx-3 py-2" to="/">Home</router-link></li>
      <li><router-link class="mx-3 py-2" to="/payers">Payers</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {

}
</script>

<style type="scss" scoped>
.router-link-exact-active {
  border-bottom: 2px solid #FFF;
}
</style>