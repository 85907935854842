<template>
  <div>
    <div class="py-5 bg-blue-500 text-white">
      <div class="container mx-auto flex items-center">
        <h2>Bill Splitter</h2>
        <Navigation />
      </div>
    </div>
    <div id="app" class="container mx-auto bg-white">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'

export default {
  name: 'App',

  components: {
    Navigation,
  },

  
}
</script>

<style>

</style>
