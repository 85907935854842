<template>
  <div>
    <slot name="menu"></slot>
    <table class="table-auto border-t w-full">
      <thead>
        <tr class="border-b-2">
          <td 
            v-for="header in headers" 
            :key="`header-${header.code}`" 
            class="p-2"
          >{{ header.label }}</td>
        </tr>
      </thead>
      <tbody>
        <slot>
          <tr 
            v-for="row in rows" 
            :key="`row-${row.id}`"
            class="border-b hover:bg-gray-100"
          >
            <td 
              v-for="header in headers" 
              :key="`row-${row.id}-${header.code}`" 
              class="p-2"
            >{{ row[header.code] }}</td>
          </tr>
        </slot>
      </tbody>
      <tfoot class="font-bold">
        <slot name="footer"></slot>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    
    rows: {
      type: Array,
      defualt: () => [],
    } 
  },
}
</script>

<style>

</style>