<template>
  <div class="my-3 h-full">
    <div 
      class="file-uploader"
      @drop.prevent="uploadDroppedFile"
      @dragover.prevent
    >
      Drag files here
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    uploadDroppedFile(e) {
      const files = e.dataTransfer.files
      
      if(files.length === 1) {
        this.$store.dispatch('bill/upload', files[0])
      }
    }
  }
}
</script>

<style>
.file-uploader {
  border: 4px dashed #555;
  border-radius: 20px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>