<template>
  <file-upload v-if="rows.length === 0" />
  <div v-else>
    <div class="flex my-3">
      <div class="flex-grow">
        <div class="flex mb-2">
          <Button @click="$refs['file-upload'].click()">Import</Button>
          <input ref="file-upload" class="hidden" type="file" @change="uploadFile">
          <Button @click="exportCsv">Export to CSV</Button>
        </div>
      </div>
      <div class="w-1/4">
        <Table :headers="payerHeaders">
          <table-row v-for="row in totals" :key="`row-${row.id}`" :headers="payerHeaders" :row="row">
            <template v-slot:amount>
              {{ formatCurrency(row.amount) }}
            </template>
          </table-row>
          <template v-slot:footer>
            <table-row :headers="payerHeaders" :row="{ payer: 'Total', amount: formatCurrency(grandTotal) }"></table-row>
          </template>
        </Table>
      </div>
    </div>
    <Table :headers="billHeaders">
      <template #menu>
        <div class="mb-2">
          <label class="mr-2">Filter</label>
          <select 
            v-model="filter"
            class="p-2 mr-2 rounded-md border"
          >
            <option :value="0">Unassigned</option>
            <option v-for="payer in payers" :key="payer.id" :value="payer.id">{{ payer.name }}</option>
            <option value="split">Split</option>
          </select>
        </div>
      </template>
      <table-row v-for="row in filteredRows(filter)" :key="`row-${row.id}`" :headers="billHeaders" :row="row">
        <template #amount>
          £{{ row.amount / 100 }}
        </template>
        <template #payer>
          <Button
            v-for="(payer, index) in payers"
            :key="index"
            @click="setPayer(payer.id, row)"
            >{{ payer.name }}</Button>
          <Button 
            variation="secondary"
            @click="setSplit(row)"
          >Split</Button>
        </template>
      </table-row>
    </Table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import FileUpload from '@/components/FileUpload'
import Table from '@/components/Table'
import TableRow from '@/components/TableRow'
import Button from '@/components/Button'

export default {
  components: {
    FileUpload,
    Table,
    TableRow,
    Button,
  },
  
  data() {
    return {
      billHeaders: [
        { code: 'date', label: 'Date' },
        { code: 'description', label: 'Description' },
        { code: 'amount', label: 'Amount' },
        { code: 'payer', label: 'Payer' },
      ],

      payerHeaders: [
        { code: 'payer', label: 'Payer' },
        { code: 'amount', label: 'Amount' },
      ],

      filter: 0, // default value 'Unassigned'
    }
  },

  computed: {
    ...mapGetters('bill', ['unassignedRows', 'filteredRows']),
    ...mapGetters('payers', ['payer']),
    ...mapState('bill', ['rows']),
    ...mapState('payers', ['payers']),

    splitTotal() {
      return this.rows
        .filter(row => row.split)
        .reduce((acc, row) => acc + row.amount, 0)
    },

    totals() {
      const totals = []
      const splitAmountPerPayer = this.splitTotal / this.payers.length

      this.payers.forEach(payer => totals.push({
        id: payer.id,
        payer: payer.name,
        amount: payer.total + splitAmountPerPayer
      }))

      return totals
    },

    grandTotal() {
      let grandTotal = this.splitTotal || 0

      this.payers.forEach(payer => grandTotal += payer.total)

      return grandTotal
    }
  },

  methods: {
    setPayer(payerId, row) {
      const newPayer = this.payer(payerId)

      if(newPayer) {
        if(row.payerId > 0) {
          const previousPayer = this.payer(row.payerId)

          if(previousPayer) {
            this.$store.commit('payers/setPayerTotal', { id: row.payerId, total: (previousPayer.total - row.amount) })
          }
        }
        
        this.$store.commit('bill/setRowValue', { attribute: 'payerId', value: payerId, rowId: row.id })
        this.$store.commit('bill/setRowValue', { attribute: 'split', value: false, rowId: row.id })
        this.$store.commit('payers/setPayerTotal', { id: payerId, total: (newPayer.total + row.amount) })
      }
    },

    setSplit(row) {
      this.$store.commit('bill/setRowValue', { attribute: 'split', value: true, rowId: row.id })
    },

    formatCurrency(value) {
      return `£${Math.round(value) / 100}`
    },

    uploadFile(e) {
      const files = e.target.files

      if(files.length === 1) {
        this.$store.dispatch('bill/upload', files[0])
      }
    },

    exportCsv() {
      let csvContent = 'data:text/csv;charset=utf-8,'

      // Totals table
      csvContent += 'Payer,Total\n'
      this.totals.forEach(total => csvContent += `${total.payer},${this.formatCurrency(total.amount)}\n`)

      csvContent += '\n'

      // Bill table
      csvContent += 'Date,Description,Amount,Split,Payer\n'
      this.rows.forEach(row => {
        const payer = this.payer(row.payerId)
        let payerName = '-'
        if(payer !== false) {
          payerName = payer.name
        }
        csvContent += `${row.date},${row.description},${this.formatCurrency(row.amount)},${row.split ? 'True': 'False'},${payerName}\n`
      })

      window.open(encodeURI(csvContent))
    }
  },

  mounted() {
    this.$store.dispatch('payers/getPayers')
  }
}
</script>

<style>

</style>