<template>
  <button 
    :class="['p-2 mr-2 rounded-md text-white', variationClasses ]"
    @click="$emit('click')"
  ><slot></slot></button>
</template>

<script>
const variations = {
  primary: 'bg-blue-500 hover:bg-blue-600',
  secondary: 'bg-gray-500 hover:bg-gray-600',
  danger: 'bg-red-500 hover:bg-red-600',
}
export default {
  props: {
    variation: {
      type: String,
      default: 'primary',
      validator: type => type.match(/(primary|secondary|danger)/)
    }
  },

  computed: {
    variationClasses() {
      return variations[this.variation]
    }
  }
}
</script>

<style>

</style>