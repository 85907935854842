
import axios from 'axios'
import Vue from 'vue'

const URL = 'api/import-csv'

const state = () => ({
  rows: [],
})

// getters
const getters = {
  unassignedRows: state => {
    return state.rows.filter(row => row.payerId === 0)
  },

  filteredRows: state => filter => {
    if(filter === 'split') {
      return state.rows.filter(row => row.split)
    }

    return state.rows.filter(row => row.payerId === filter && !row.split)
  },
}

// actions
const actions = {
  upload({ commit, dispatch }, file) {
    const formData = new FormData()

    formData.append('file', file, file.fileName)

    axios.post(URL, formData)
      .then(resp => {
        commit('setRows', resp.data)
        dispatch('payers/resetTotals', null, { root: true })
      })
  },

  clearPayerRows({ commit, getters }, payerId) {
    getters.rowsAssignedTo(payerId).forEach(row => commit('setPayer', { rowId: row.id, payerId: 0 }))
  }
}

// mutations
const mutations = {
  setRows(state, rows) {
    state.rows = rows
  },

  setRowValue(state, payload) {
    const index = state.rows.map(row => row.id).indexOf(payload.rowId)

    if(index > -1) {
      const row = {...state.rows[index]}

      row[payload.attribute] = payload.value

      Vue.set(state.rows, index, row)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}