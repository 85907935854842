<template>
  <div>
    <Table :headers="headers">
      <table-row v-for="row in payers" :key="`row-${row.id}`" :headers="headers" :row="row">
        <template v-slot:total>
          £{{ row.total / 100 }}
        </template>
        <template v-slot:action>
          <Button variation="danger" @click="deletePayer(row.id)">Delete</Button>
        </template>
      </table-row>
      <table-row :headers="headers" :row="{}">
        <template v-slot:name>
          <input class="border p-1 rounded" type="text" v-model="name" />
        </template>
        <template v-slot:action>
          <Button @click="addPayer">Add payer</button>
        </template>
      </table-row>
    </Table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Table from '@/components/Table'
import TableRow from '@/components/TableRow'
import Button from '@/components/Button'

export default {
  components: {
    Table,
    TableRow,
    Button,
  },
  
  data() {
    return {
      name: '',
      headers: [
        { code: 'name', label: 'Payer' },
        { code: 'action', label: '' },
      ],
    }
  },
  
  computed: {
    ...mapState('payers', ['payers']),

    nextId() {
      return Object.keys(this.payers).length + 1
    }
  },

  methods: {
    addPayer() {
      this.$store.dispatch('payers/addPayer', { id: this.nextId, name: this.name, total: 0 })
      this.resetForm()
    },

    deletePayer(id) {
      this.$store.dispatch('payers/deletePayer', id)
      this.$store.dispatch('bill/clearPayerRows', id)
    },

    resetForm() {
      this.id = ''
      this.name = ''
    }
  },

  mounted() {
    this.$store.dispatch('payers/getPayers')
  }
}
</script>

<style>

</style>