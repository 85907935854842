<template>
  <tr class="border-b hover:bg-gray-100">
    <td v-for="header in headers" :key="header.code" class="p-2">
      <slot :name="header.code">{{ row[header.code] }}</slot>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },

    row: {
      type: Object,
      default: () => {},
    }
  }
}
</script>

<style>

</style>