import Vue from 'vue'

const state = () => ({
  payers: [],
})

// getters
const getters = {
  payer: state => id => {
    const index = state.payers.map(payer => payer.id).indexOf(id)

    if(index > -1) {
      return state.payers[index]
    }

    return false
  }
}

// actions
const actions = {
  getPayers({ commit }) {
    const payers = localStorage.getItem('payers')

    commit('setPayers', JSON.parse(payers) || [])
  },
  
  addPayer({ state, commit }, payer) {
    const payers = [...state.payers]
    payers.push(payer)
    commit('setPayers', payers)
  },

  deletePayer({ state, commit }, id) {
    const payers = [...state.payers]
    const index = payers.map(payer => payer.id).indexOf(id)

    if(index !== -1) {
      payers.splice(index)
      commit('setPayers', payers)
    }
  },

  resetTotals({ state, commit }) {
    state.payers.forEach(payer => commit('setPayerTotal', { id: payer.id, total: 0 }))
  }
}

// mutations
const mutations = {
  setPayers(state, payers) {
    state.payers = payers
    localStorage.setItem('payers', JSON.stringify(state.payers))
  },

  setPayerTotal(state, payload) {
    const index = state.payers.map(payer => payer.id).indexOf(payload.id)

    if(index !== -1) {
      Vue.set(state.payers[index], 'total', payload.total)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}